














	import Vue from "vue"
	import Component from "vue-class-component"
	import ActionButton from "components/button/ActionButton.vue"
	import IconLabel from "components/supplemental/IconLabel.vue"
	import {Prop} from "vue-property-decorator"

	import eventBus from "lib/vue/eventBus"

	@Component({
		components: {
			ActionButton,
			IconLabel
		}
	})
	export default class Hamburger extends Vue {
		@Prop({type: Boolean, default: false}) disabled!: boolean
		@Prop({type: Boolean, default: null}) opened!: boolean | null // if opened is given, isOpen will be ignored
		@Prop({type: String, default: ""}) event!: string

		isOpen = false

		clicked() {
			if (!this.event) {
				this.isOpen = !this.isOpen
			}
		}

		mounted() {
			if (this.event) {
				eventBus.on(this.event, () => {
					this.isOpen = !this.isOpen
				}, this)
			}
		}

	}
