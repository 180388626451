












	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	@Component
	export default class RangeInput extends Vue {
		@Prop({required: false, type: Number}) value!: number
		@Prop({required: true, type: Number}) min!: number
		@Prop({required: true, type: Number}) max!: number
		@Prop({required: false, type: Number}) initial!: number
		@Prop({required: false, type: Function, default: (a: number) => a.toString()}) cb!: (a: number) => string
		@Prop({required: false, type: Number, default: 1}) step!: number

		changed = false

		@Watch("value")
		valueChanged() {
			this.changed = true
		}

		emitInput(n: any) {
			this.$emit("input", Number(n.target.value))
		}

		calculatePercentage(value?: number): number {
			if (typeof value !== "number") {
				return 0
			} else {
				const percentage = (value - this.min) * 100 / (this.max - this.min) - 50
				return (percentage > 50 ? 50 : percentage < -50 ? -50 : percentage) * 0.96
			}
		}

		get isSelected() {
			return this.changed || this.value !== this.initial
		}
	}
