







	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	@Component
	export default class RequestLoader extends Vue {
		@Prop({type: String, required: false}) loadingMessage!: string
	}
