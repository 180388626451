
















	import Vue from "vue"
	import {Component, Prop} from "vue-property-decorator"

	import Container from "components/generic/Container.vue"
	import {single} from "components/@directives/selectable"

	import {Predicate} from "lib/types/function"

	export interface WizardItem {
		id: string
		complete: boolean
		[key: string]: any
	}

	@Component({
		components: {
			Container
		},
		directives: {
			single
		}
	})
	export default class Wizard extends Vue {
		@Prop({required: true, type: String}) value!: string
		@Prop({required: true, type: Array}) items!: Array<WizardItem>
		@Prop({required: false, type: Function, default: (el: Element) => el.classList.contains("selectable")}) selectable!: Predicate<Element>
		@Prop({required: false, type: String, default: "icons", validator: (v: string) => ["icons", "dots"].includes(v)}) type!: string
		@Prop({required: false, type: Boolean, default: false}) vertical!: boolean
		@Prop({required: false, type: Boolean, default: false}) clickable!: boolean

		get selectableItems() {
			return this.items.slice(0, this.items.findIndex(x => !x.complete) + 1)
		}

		isSelectable(item: WizardItem) {
			return this.clickable && this.selectableItems.includes(item)
		}

		select(item: WizardItem) {
			if (this.isSelectable(item)) {
				this.$emit("input", item.id)
			}
		}
	}
