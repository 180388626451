






























	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	import RangeInput from "custom/RangeInput.vue"
	import {PensiongoalItem} from "store/pensiongoal/types"

	import {IconPiggybank, IconAz, IconBike, IconCar, IconCheckPaper, IconCouch, IconHealthBag, IconHouse, IconPhone,
		IconShoppingCart, IconSportTrainer, IconStudent, IconWaterTap} from "custom/icons"

	const shortMoney = (n: number) => Vue.filter("money")(Vue.filter("truncate")(n), 0)

	@Component({
		components: {
			RangeInput,
			IconPiggybank,
			IconAz,
			IconBike,
			IconCar,
			IconCheckPaper,
			IconCouch,
			IconHealthBag,
			IconHouse,
			IconPhone,
			IconShoppingCart,
			IconSportTrainer,
			IconStudent,
			IconWaterTap
		}
	})
	export default class PensiongoalInput extends Vue {
		@Prop({required: true, type: Object}) value!: PensiongoalItem
		@Prop({required: false, type: Number, default: 9999}) max!: number
		@Prop({required: false, type: Boolean, default: false}) disableAverage!: boolean
		@Prop({required: false, type: Boolean, default: false}) overruleAverage!: boolean

		formatMoney(n: number) {
			return shortMoney(n)
		}

		filterKey(e: any) {
			if (e.key === ".") { return e.preventDefault() }
		}

		emitItem(n: number) {
			this.$emit("input", {
				category: this.value.category,
				average: this.value.average,
				value: n
			})
		}
	}
