












































































	import Vue from "vue"
	import Component from "vue-class-component"
	import {namespace, State} from "vuex-class"

	import {IconHouse, IconDocument, IconEnvelope, IconPerson, IconPencil, IconRepeat, IconFolder, IconPiggybank} from "custom/icons"

	import LinkButton from "components/button/LinkButton.vue"
	import Badge from "components/supplemental/Badge.vue"
	import Hamburger from "custom/Hamburger.vue"
	import IconLabel from "custom/IconLabel.vue"
	import NavigationBar from "components/container/NavigationBar.vue"
	import MenuModal from "custom/modal/MenuModal.vue"
	import {Locale} from "store/types"
	import load from "src/i18n/load"
	import {save as saveLocale} from "lib/i18n/session"
	import {Policyholder} from "api/models/policyholder"
	import {session} from "api/request"

	const messages = namespace("messages")
	const dossier = namespace("dossier")

	@Component({
		components: {
			LinkButton,
			Badge,
			Hamburger,
			IconLabel,
			NavigationBar,
			MenuModal,

			// icons
			IconHouse,
			IconDocument,
			IconEnvelope,
			IconPerson,
			IconPencil,
			IconRepeat,
			IconFolder,
			IconPiggybank
		}
	})
	export default class PageHeader extends Vue {
		@State active!: boolean
		@State policyholder!: Policyholder
		@State locale!: Locale

		@messages.Getter unreadMessages!: number
		@dossier.Getter unreadDocuments!: number

		menuModalOpened = false

		async changeLanguage(lang: Locale) {
			const done = await saveLocale(load, lang)
			if (done) {
				this.$store.commit("locale", lang)
				if (this.active) {
					location.reload()
				}
			}
		}

		async logout() {
			await session.logout()
		}

	}
