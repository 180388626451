














	import Vue from "vue"
	import Component from "vue-class-component"

	import Tooltip from "components/supplemental/Tooltip.vue"

	@Component({
		components: {
			Tooltip
		}
	})
	export default class Choice extends Vue {
	}
