












	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	@Component
	export default class ToggleButton extends Vue {
		@Prop({required: true, type: Boolean}) isSelected!: boolean

		toggle() {
			this.$emit("toggle", !this.isSelected)
		}
	}
