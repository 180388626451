



















	import Vue from "vue"
	import Component from "vue-class-component"

	import Notice from "components/supplemental/Notice.vue"
	import {Prop} from "vue-property-decorator"
	import {isArray} from "lodash-es"

	@Component({
		components: {
			Notice
		}
	})
	export default class ErrorMessage extends Vue {
		@Prop({required: true}) errors?: string | Array<string>

		get error() {
			return isArray(this.errors) ? this.errors[0] : this.errors
		}
	}
