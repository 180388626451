







	import Vue from "vue"
	import {Component, Prop} from "vue-property-decorator"

	import pad from "lib/number/pad"

	@Component({
		filters: {
			formatDecimals: (n: number) => pad(2, Math.round(n % 1 * 100))
		}
	})
	export default class Amount extends Vue {
		@Prop({required: true}) amount!: number
		@Prop({required: false, default: true}) showDecimals!: boolean
	}
