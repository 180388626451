









	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	import { single, multiple } from "components/@directives/selectable"

	@Component({
		directives: { single, multiple }
	})
	export default class AccordionList extends Vue {
		@Prop({type: Boolean, default: false, required: false}) multiple!: boolean
		@Prop({type: String, default: "plus", validator: (v: any) => ["chevron", "plus", "filled"].includes(v)}) type!: string
	}
