















	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	import eventBus from "lib/vue/eventBus"

	@Component
	export default class Modal extends Vue {
		@Prop({required: false, type: String}) event?: string

		visible = false
		loaded = false

		@Watch("event")
		eventChanged(event: string | undefined, oldEvent: string | undefined) {
			if (oldEvent) {
				eventBus.off(this, oldEvent, this.display)
			}
			if (event) {
				eventBus.on(event, this.display, this)
			}
		}

		beforeMount() {
			if (this.event) {
				eventBus.on(this.event, this.display, this)
			}
		}

		mounted() {
			// FIXME: timeout necessary to prevent the modal to appear briefly before transitioning.
			setTimeout(() => this.loaded = true, 200)
		}

		beforeDestroy() {
			if (this.event) {
				eventBus.off(this, this.event, this.display)
			}
		}

		display() {
			this.visible = true
			this.$emit("opened")
		}

		close() {
			this.visible = false
			this.$emit("closed")
		}
	}
