




	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	@Component
	export default class Badge extends Vue {
		@Prop({required: true}) count!: number
	}
