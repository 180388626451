
















	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Card extends Vue {
		hasTitle = false
		hasIcon = false

		mounted() {
			this.hasTitle = !!this.$slots.title
			this.hasIcon = !!this.$slots.icon
		}
	}
