







	import Component from "vue-class-component"
	import InputField from "./InputField"

	@Component
	export default class Checkbox extends InputField<boolean> {}
