


















































































	import Vue from "vue"
	import Component from "vue-class-component"
	import {State, namespace} from "vuex-class"

	import {
		IconArrow,
		IconMapsFilled,
		IconStar
	} from "custom/icons"
	import IconLabel from "custom/IconLabel.vue"

	import Card from "components/panel/Card.vue"
	import LinkButton from "components/button/LinkButton.vue"

	import {StoreAction} from "lib/types/vuex"
	import {PensionProfilePayload, initialPensionProfileState, initialValueTransferState, ValuetransferPayload} from "store/state/types"
	import {AllianzPolicy} from "store/pensiondetails/types"

	const progressState = namespace("progressState")
	const pensiondetails = namespace("pensiondetails")
	const policyholder = namespace("policyholder")

	@Component({
		components: {
			Card,
			IconLabel,
			LinkButton,

			IconArrow,
			IconMapsFilled,
			IconStar
		}
	})
	export default class PageFooter extends Vue {
		@State active!: boolean
		@progressState.Action getProgressState!: StoreAction<PensionProfilePayload | ValuetransferPayload>
		@pensiondetails.Getter policiesWithMoreThan10RProfiles!: Array<AllianzPolicy>
		@pensiondetails.Getter policiesWithLessThan3RProfiles!: Array<AllianzPolicy>
		@pensiondetails.Getter policyHolderMainStatus!: string
		@pensiondetails.Getter policiesWithOwnInvestmentStrategy!: boolean
		@pensiondetails.Getter isExtraSavingsAvailable!: boolean
		@pensiondetails.Getter hasOldAgePension!: boolean
		@pensiondetails.Getter hasAllianzAdvantageProduct!: boolean
		@pensiondetails.Getter hasPPIProduct!: boolean
		@pensiondetails.Getter hasRetireeVariablePolicy!: boolean
		@pensiondetails.Getter hasRetireeFixedPolicy!: boolean
		@policyholder.Getter isRetired!: boolean

		loaded = false
		pensionProfileState: PensionProfilePayload = initialPensionProfileState
		valuetransferState: ValuetransferPayload = initialValueTransferState

		async mounted() {
			await Vue.nextTick()
			if (this.active) {
				this.pensionProfileState = await this.getProgressState(this.pensionProfileState)
				this.valuetransferState = await this.getProgressState(this.valuetransferState)
				this.loaded = true
			}
		}

		hasEnddate(endDate: any): boolean {
			return endDate !== null && endDate.endDateTime !== undefined
		}

		get isSleeper(): boolean {
			return this.policyHolderMainStatus === "Sleeper"
		}

		get isRiskprofileComplete(): boolean {
			return this.hasEnddate(this.pensionProfileState.steps[3])
		}

		get isValueTransferCompleted(): boolean {
			return this.valuetransferState.status === "COMPLETED" ? true : false
		}

		get showRiskProfileLink(): boolean {
			return !this.isRetired &&
				!this.policiesWithOwnInvestmentStrategy &&
				this.hasOldAgePension &&
				this.policiesWithLessThan3RProfiles.length === 0
		}

		get showValueTransferLink(): boolean {
			return !["Sleeper", "NOACCESS"].includes(this.policyHolderMainStatus) && !this.isValueTransferCompleted && this.hasOldAgePension && !this.isRetired
		}

		get showExtraSavingsLink(): boolean {
			return !["Sleeper", "NOACCESS"].includes(this.policyHolderMainStatus) && this.isExtraSavingsAvailable &&
				this.hasOldAgePension && !this.isRetired
		}

		get showPlannerLink(): boolean {
			return this.policyHolderMainStatus === "Active" && !this.isSleeper && this.hasOldAgePension && !this.isRetired
		}

		get showInvestmentLink(): boolean {
			return this.hasOldAgePension && this.hasRetireeVariablePolicy
		}
	}
