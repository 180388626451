








	import Vue from "vue"
	import Component from "vue-class-component"

	import {Prop} from "vue-property-decorator"

	@Component
	export default class BigSwitch extends Vue {
		@Prop({type: Boolean}) value?: boolean

		toggled(_e: MouseEvent): void {
			this.$emit("input", !this.value)
		}
	}
