



















	import Vue from "vue"
	import Component from "vue-class-component"

	import PageHeader from "custom/PageHeader.vue"
	import PageFooter from "custom/PageFooter.vue"
	import PToaster from "custom/ToasterUI.vue"

	@Component({
		components: {
			PageHeader,
			PageFooter,
			PToaster
		}
	})
	export default class Page extends Vue {
	}
