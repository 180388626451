




	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"
	import InputField from "./InputField"

	@Component
	export default class TextField extends InputField {
		@Prop({required: false, type: Number}) maxlength?: number
		@Prop({required: false, type: Boolean, default: false}) focus!: boolean

		get inputListeners() {
			return {
				...this.$listeners,
				input: (event: Event) => {
					this.$emit("input", (event.target as HTMLInputElement).value)
				}
			}
		}

		mounted() {
			if (this.focus) {
				(this.$el as HTMLInputElement).select()
			}
		}
	}
